import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import CustomSelect from './CustomSelect';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function AnalyticsInterface() {
  const navigate = useNavigate();
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeFilter, setTimeFilter] = useState('all');

  const timeFilterOptions = [
    { value: 'all', label: 'Alle Zeit' },
    { value: 'week', label: 'Letzte Woche' },
    { value: 'month', label: 'Letzter Monat' },
    { value: 'year', label: 'Letztes Jahr' }
  ];

  const fetchAnalyticsData = useCallback(async () => {
    try {
      const response = await api.get(`/analytics?timeFrame=${timeFilter}`);
      setAnalyticsData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Laden der Analysedaten:', error);
      setLoading(false);
    }
  }, [timeFilter]);

  useEffect(() => {
    fetchAnalyticsData();
  }, [fetchAnalyticsData]);

  if (loading) return <div>Laden...</div>;

  return (
    <div className="feedback-container">
      <h2 style={{ color: '#E8fc6c' }}>Feedback-Auswertung</h2>
      
      <div className="form-group">
        <label>Zeitraum:</label>
        <CustomSelect 
          options={timeFilterOptions}
          value={timeFilter}
          onChange={setTimeFilter}
          placeholder="Zeitraum wählen"
        />
      </div>

      {analyticsData && (
        <div className="charts-container" style={{ background: '#1a1a1a', padding: '20px', marginTop: '20px' }}>
          <Bar 
            data={analyticsData.chartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                  labels: {
                    color: '#E8fc6c'
                  }
                },
                title: {
                  display: true,
                  text: 'Feedback-Übersicht',
                  color: '#E8fc6c'
                }
              },
              scales: {
                y: {
                  ticks: { color: '#E8fc6c' },
                  grid: { color: '#333' }
                },
                x: {
                  ticks: { color: '#E8fc6c' },
                  grid: { color: '#333' }
                }
              }
            }}
          />
        </div>
      )}

      <div className="type-buttons" style={{ marginTop: '20px' }}>
        <button 
          type="button"
          onClick={() => navigate('/admin/dashboard')}
          className="action-button"
        >
          Zurück zum Dashboard
        </button>
      </div>
    </div>
  );
}

export default AnalyticsInterface; 