import React, { useState, useRef } from 'react';
import api from '../api';
import CustomSelect from './CustomSelect';
import CommentTimeline from './CommentTimeline';
import ImageModal from './ImageModal';

function ProjectDetailModal({ project, onClose, onUpdate, departments }) {
  const dateInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [details, setDetails] = useState({
    nextSteps: project.nextSteps || '',
    responsiblePerson: project.responsiblePerson || '',
    deadline: project.deadline ? new Date(project.deadline).toISOString().split('T')[0] : '',
    priority: project.priority || 'medium',
    estimatedTime: project.estimatedTime || '',
    department: project.department || '',
    costEstimate: project.costEstimate || '',
    implementationStatus: project.implementationStatus || 'not_started'
  });

  const handleDateClick = () => {
    dateInputRef.current.showPicker();
  };

  const handleSave = async () => {
    try {
      const updateData = {
        nextSteps: details.nextSteps || null,
        responsiblePerson: details.responsiblePerson || null,
        deadline: details.deadline || null,
        priority: details.priority || 'medium',
        estimatedTime: details.estimatedTime || null,
        department: details.department || null,
        costEstimate: details.costEstimate || null,
        implementationStatus: details.implementationStatus || 'not_started',
        status: project.status || 'pending',
        forPresentation: project.forPresentation || false,
        type: project.type,
        title: project.title,
        description: project.description,
        author: project.author
      };

      console.log('Sende Update-Daten:', updateData);

      const response = await api.put(`/projects/${project.id}`, updateData);

      if (response.status === 200) {
        console.log('Update erfolgreich:', response.data);
        await onUpdate();
        onClose();
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Projekts:', error);
      console.error('Fehler Details:', error.response?.data);
      alert(`Fehler beim Speichern der Änderungen: ${error.response?.data?.message || error.message}`);
    }
  };

  const implementationStatusOptions = [
    { value: 'not_started', label: 'Nicht begonnen' },
    { value: 'planning', label: 'In Planung' },
    { value: 'in_progress', label: 'In Umsetzung' },
    { value: 'testing', label: 'In Testphase' },
    { value: 'completed', label: 'Abgeschlossen' },
    { value: 'on_hold', label: 'Pausiert' }
  ];

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content detail-modal" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        
        <div className="modal-header">
          <h3>{project.title}</h3>
          <div className="project-meta">
            <span>Eingereicht von: {project.author}</span>
            <span>Datum: {new Date(project.createdAt).toLocaleDateString('de-DE')}</span>
          </div>
        </div>

        <div className="modal-body">
          <div className="project-description">
            <p>{project.description}</p>
            {project.imagePath && (
              <div className="feedback-image-preview" onClick={() => setSelectedImage(`${process.env.REACT_APP_API_URL}/uploads/${project.imagePath.split('/').pop()}`)}>
                <img 
                  src={`${process.env.REACT_APP_API_URL}/uploads/${project.imagePath.split('/').pop()}`}
                  alt="Feedback Bild"
                  className="modal-image"
                />
                <div className="image-overlay">
                  <span>🔍 Vergrößern</span>
                </div>
              </div>
            )}
          </div>

          <div className="detail-form">
            <div className="form-group">
              <label>Verantwortliche Person:</label>
              <input
                type="text"
                value={details.responsiblePerson}
                onChange={(e) => setDetails({...details, responsiblePerson: e.target.value})}
                placeholder="Name der verantwortlichen Person"
                className="styled-input"
              />
            </div>

            <div className="form-group">
              <label>Abteilung:</label>
              <CustomSelect
                options={departments.map(dept => ({ value: dept.name, label: dept.name }))}
                value={details.department}
                onChange={(value) => setDetails({...details, department: value})}
                placeholder="Abteilung auswählen"
              />
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Priorität:</label>
                <CustomSelect
                  options={[
                    { value: 'low', label: 'Niedrig' },
                    { value: 'medium', label: 'Mittel' },
                    { value: 'high', label: 'Hoch' }
                  ]}
                  value={details.priority}
                  onChange={(value) => setDetails({...details, priority: value})}
                  placeholder="Priorität wählen"
                />
              </div>

              <div className="form-group">
                <label>Deadline:</label>
                <div className="date-input-container" onClick={handleDateClick}>
                  <input
                    ref={dateInputRef}
                    type="date"
                    value={details.deadline}
                    onChange={(e) => setDetails({...details, deadline: e.target.value})}
                    className="styled-input date-input"
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Geschätzter Zeitaufwand:</label>
                <input
                  type="text"
                  value={details.estimatedTime}
                  onChange={(e) => setDetails({...details, estimatedTime: e.target.value})}
                  placeholder="z.B. 2 Wochen, 3 Monate"
                  className="styled-input"
                />
              </div>

              <div className="form-group">
                <label>Geschätzte Kosten:</label>
                <input
                  type="text"
                  value={details.costEstimate}
                  onChange={(e) => setDetails({...details, costEstimate: e.target.value})}
                  placeholder="z.B. 5000€, nach Aufwand"
                  className="styled-input"
                />
              </div>
            </div>

            <div className="form-group">
              <label>Umsetzungsstatus:</label>
              <CustomSelect
                options={implementationStatusOptions}
                value={details.implementationStatus}
                onChange={(value) => setDetails({...details, implementationStatus: value})}
                placeholder="Status wählen"
              />
            </div>

            <div className="form-group">
              <label>Nächste Schritte:</label>
              <textarea
                value={details.nextSteps}
                onChange={(e) => setDetails({...details, nextSteps: e.target.value})}
                placeholder="Beschreiben Sie die nächsten Schritte..."
                className="styled-textarea"
              />
            </div>

            <CommentTimeline projectId={project.id} />
          </div>
        </div>

        <div className="modal-footer">
          <button onClick={handleSave} className="save-button">
            Speichern
          </button>
        </div>
      </div>

      {selectedImage && (
        <ImageModal 
          imageUrl={selectedImage} 
          onClose={() => setSelectedImage(null)}
        />
      )}
    </div>
  );
}

export default ProjectDetailModal; 