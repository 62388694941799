import React from 'react';

function ImageModal({ imageUrl, onClose }) {
  return (
    <div 
      className="image-modal"
      onClick={onClose}
    >
      <button 
        className="image-modal-close"
        onClick={onClose}
      >
        ×
      </button>
      <img 
        src={imageUrl} 
        alt="Vergrößerte Ansicht"
      />
    </div>
  );
}

export default ImageModal; 