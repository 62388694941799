import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import FeedbackForm from './components/FeedbackForm';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import AnalyticsInterface from './components/AnalyticsInterface';
import UserManagement from './components/UserManagement';
import DepartmentManager from './components/DepartmentManager';
import Settings from './components/Settings';
import AdminLayout from './components/AdminLayout';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import api from './api';

export const PresentationContext = createContext();
export const AuthContext = createContext();

function App() {
  const [presentationMode, setPresentationMode] = useState(false);
  const [token, setToken] = useState(() => {
    return localStorage.getItem('token') || null;
  });
  
  const [user, setUser] = useState(() => {
    try {
      const storedUser = localStorage.getItem('user');
      return storedUser ? JSON.parse(storedUser) : null;
    } catch (error) {
      console.error('Fehler beim Laden des Users:', error);
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      return null;
    }
  });
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      if (token) {
        try {
          await api.get('/api/auth/check');
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Auth check failed:', error);
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          setToken(null);
          setUser(null);
          setIsAuthenticated(false);
        }
      }
      setIsLoading(false);
    };

    checkAuth();
  }, [token]);

  const handleLogin = (newToken, newUser) => {
    localStorage.setItem('token', newToken);
    localStorage.setItem('user', JSON.stringify(newUser));
    setToken(newToken);
    setUser(newUser);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setToken(null);
    setUser(null);
    setIsAuthenticated(false);
  };

  const togglePresentationMode = () => {
    setPresentationMode(!presentationMode);
  };

  if (isLoading) {
    return <div>Laden...</div>;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, handleLogout }}>
      <PresentationContext.Provider value={{ presentationMode, togglePresentationMode }}>
        <Router>
          <Routes>
            <Route path="/" element={<FeedbackForm />} />
            <Route 
              path="/admin/login" 
              element={
                isAuthenticated ? 
                <Navigate to="/admin/dashboard" replace /> : 
                <Login onLogin={handleLogin} />
              } 
            />
            
            <Route path="/admin/*" element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <AdminLayout>
                  <Routes>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="analytics" element={<AnalyticsInterface />} />
                    <Route path="users" element={
                      <AdminRoute>
                        <UserManagement />
                      </AdminRoute>
                    } />
                    <Route path="departments" element={
                      <AdminRoute>
                        <DepartmentManager />
                      </AdminRoute>
                    } />
                    <Route path="settings" element={
                      <AdminRoute>
                        <Settings />
                      </AdminRoute>
                    } />
                    <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
                  </Routes>
                </AdminLayout>
              </PrivateRoute>
            } />
            
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </PresentationContext.Provider>
    </AuthContext.Provider>
  );
}

export default App; 