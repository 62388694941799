import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import ImageModal from './ImageModal';
import ProjectDetailModal from './ProjectDetailModal';
import CustomSelect from './CustomSelect';
import DepartmentManager from './DepartmentManager';
import { PresentationContext } from '../App';

function Dashboard() {
  const navigate = useNavigate();
  const { presentationMode } = useContext(PresentationContext);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showDepartmentManager] = useState(false);
  const [filterType, setFilterType] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    fetchProjects();
    fetchDepartments();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await api.get('/projects');
      setProjects(response.data);
    } catch (error) {
      console.error('Fehler beim Laden der Feedbacks:', error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await api.get('/api/departments');
      setDepartments(response.data);
    } catch (error) {
      console.error('Fehler beim Laden der Abteilungen:', error);
    }
  };

  const getTypeText = (type) => {
    const typeMap = {
      'complaint': 'Beschwerde',
      'praise': 'Lob',
      'suggestion': 'Verbesserungsvorschlag'
    };
    return typeMap[type] || type;
  };

  const getTypeColor = (type) => {
    const colorMap = {
      'complaint': '#ff6b6b',
      'praise': '#51cf66',
      'suggestion': '#ffd43b'
    };
    return colorMap[type] || '#E8fc6c';
  };

  const statusOptions = [
    { value: 'pending', label: 'Ausstehend' },
    { value: 'in_progress', label: 'In Bearbeitung' },
    { value: 'completed', label: 'Abgeschlossen' }
  ];

  // Filtere die Projekte basierend auf den ausgewählten Filtern
  const filteredProjects = projects
    .filter(project => filterType === 'all' || project.type === filterType)
    .filter(project => filterStatus === 'all' || project.status === filterStatus)
    .filter(project => !presentationMode || (presentationMode && project.forPresentation));

  return (
    <div className="feedback-page">
      <div className="feedback-container">
        {showDepartmentManager ? (
          <DepartmentManager 
            departments={departments}
            onUpdate={fetchDepartments}
          />
        ) : (
          <>
            <div className="dashboard-controls">
              <h2 style={{ color: '#E8fc6c' }}>
                {presentationMode ? 'Präsentationsansicht' : 'Feedback-Übersicht'}
              </h2>
              
              <div className="filter-controls">
                <CustomSelect
                  options={[
                    { value: 'all', label: 'Alle Typen' },
                    { value: 'suggestion', label: 'Verbesserungsvorschläge' },
                    { value: 'complaint', label: 'Beschwerden' },
                    { value: 'praise', label: 'Lob' }
                  ]}
                  value={filterType}
                  onChange={setFilterType}
                  placeholder="Typ filtern"
                />
                <CustomSelect
                  options={[
                    { value: 'all', label: 'Alle Status' },
                    { value: 'pending', label: 'Ausstehend' },
                    { value: 'in_progress', label: 'In Bearbeitung' },
                    { value: 'completed', label: 'Abgeschlossen' }
                  ]}
                  value={filterStatus}
                  onChange={setFilterStatus}
                  placeholder="Status filtern"
                />
              </div>
            </div>
            
            <div className="feedback-grid">
              {filteredProjects.map(project => (
                <div 
                  key={project.id} 
                  className={`feedback-card priority-${project.priority} ${project.forPresentation ? 'for-presentation' : ''}`}
                  data-type={project.type}
                >
                  <div className="feedback-card-header" style={{ backgroundColor: getTypeColor(project.type) }}>
                    <div className="header-left">
                      <span className="feedback-type">{getTypeText(project.type)}</span>
                    </div>
                    <span className="feedback-date">
                      {new Date(project.createdAt).toLocaleDateString('de-DE')}
                    </span>
                  </div>

                  <div className="feedback-card-content">
                    <div className="feedback-main-info">
                      <h3>{project.title}</h3>
                      <p className="feedback-description">{project.description}</p>
                      
                      {project.imagePath && (
                        <div className="feedback-image-preview" onClick={() => setSelectedImage(`${process.env.REACT_APP_API_URL}/uploads/${project.imagePath.split('/').pop()}`)}>
                          <img 
                            src={`${process.env.REACT_APP_API_URL}/uploads/${project.imagePath.split('/').pop()}`}
                            alt="Feedback Bild"
                          />
                          <div className="image-overlay">
                            <span>🔍 Vergrößern</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="feedback-meta">
                      <div className="meta-row">
                        <span className="meta-label">Von:</span>
                        <span className="meta-value">{project.author}</span>
                      </div>
                      {project.priority && (
                        <div className="meta-row">
                          <span className="meta-label">Priorität:</span>
                          <span className={`meta-value priority-badge priority-${project.priority}`}>
                            {project.priority === 'high' && '⚡ Hohe'}
                            {project.priority === 'medium' && '⚠️ Mittlere'}
                            {project.priority === 'low' && '📌 Niedrige'}
                            {' Priorität'}
                          </span>
                        </div>
                      )}
                      {project.responsiblePerson && (
                        <div className="meta-row">
                          <span className="meta-label">Verantwortlich:</span>
                          <span className="meta-value">{project.responsiblePerson}</span>
                        </div>
                      )}
                      {project.deadline && (
                        <div className="meta-row">
                          <span className="meta-label">Deadline:</span>
                          <span className="meta-value">{new Date(project.deadline).toLocaleDateString('de-DE')}</span>
                        </div>
                      )}
                    </div>

                    <div className="feedback-status">
                      <CustomSelect
                        options={statusOptions}
                        value={project.status}
                        onChange={async (value) => {
                          try {
                            await api.put(`/projects/${project.id}`, {
                              status: value
                            });
                            fetchProjects();
                          } catch (error) {
                            console.error('Fehler beim Aktualisieren des Status:', error);
                          }
                        }}
                        placeholder="Status wählen"
                      />
                    </div>

                    {project.tags && project.tags.length > 0 && (
                      <div className="feedback-tags">
                        {project.tags.map((tag, index) => (
                          <span key={index} className="tag">{tag}</span>
                        ))}
                      </div>
                    )}

                    <div className="feedback-actions">
                      <button
                        onClick={() => setSelectedProject(project)}
                        className="action-button"
                      >
                        {project.forPresentation ? 'Details bearbeiten' : 'Details hinzufügen'}
                      </button>
                      <button
                        onClick={async () => {
                          try {
                            await api.put(`/projects/${project.id}`, {
                              forPresentation: !project.forPresentation
                            });
                            fetchProjects();
                          } catch (error) {
                            console.error('Fehler beim Aktualisieren des Präsentationsmodus:', error);
                          }
                        }}
                        className={`presentation-toggle ${project.forPresentation ? 'active' : ''}`}
                      >
                        {project.forPresentation ? '🎯 In Präsentation' : '⚪ Nicht in Präsentation'}
                      </button>
                    </div>

                    {presentationMode && project.forPresentation && (
                      <div className="presentation-info">
                        {project.nextSteps && (
                          <div className="info-section">
                            <h4>Nächste Schritte</h4>
                            <p>{project.nextSteps}</p>
                          </div>
                        )}
                        {project.presentationNotes && (
                          <div className="info-section">
                            <h4>Notizen</h4>
                            <p>{project.presentationNotes}</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {selectedImage && (
          <ImageModal 
            imageUrl={selectedImage} 
            onClose={() => setSelectedImage(null)}
          />
        )}

        {selectedProject && (
          <ProjectDetailModal
            project={selectedProject}
            departments={departments}
            onClose={() => setSelectedProject(null)}
            onUpdate={fetchProjects}
          />
        )}

        <div className="dashboard-footer">
          <button 
            type="button"
            onClick={() => navigate('/admin/analytics')}
            className="analytics-button"
          >
            Zur Auswertung
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard; 