import React, { useState, useEffect } from 'react';
import api from '../api';

function CommentTimeline({ projectId }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const loadComments = async () => {
      try {
        const response = await api.get(`/api/projects/${projectId}/comments`);
        if (isMounted) {
          setComments(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error('Fehler beim Laden der Kommentare:', error);
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    loadComments();

    return () => {
      isMounted = false;
    };
  }, [projectId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      await api.post(`/api/projects/${projectId}/comments`, {
        content: newComment.trim()
      });
      setNewComment('');
      
      // Kommentare neu laden
      const response = await api.get(`/api/projects/${projectId}/comments`);
      setComments(response.data);
    } catch (error) {
      console.error('Fehler beim Erstellen des Kommentars:', error);
    }
  };

  const handleDelete = async (commentId) => {
    if (!window.confirm('Möchten Sie diesen Kommentar wirklich löschen?')) return;

    try {
      await api.delete(`/api/comments/${commentId}`);
      
      // Kommentare neu laden
      const response = await api.get(`/api/projects/${projectId}/comments`);
      setComments(response.data);
    } catch (error) {
      console.error('Fehler beim Löschen des Kommentars:', error);
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return <div className="comments-loading">Lade Kommentare...</div>;
  }

  return (
    <div className="comments-section">
      <h4>Kommentare</h4>
      
      <form onSubmit={handleSubmit} className="comment-form">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Neuer Kommentar..."
          className="styled-textarea"
        />
        <button type="submit" className="save-button">
          Kommentar hinzufügen
        </button>
      </form>

      <div className="comments-timeline">
        {comments.map(comment => (
          <div key={comment.id} className="comment-item">
            <div className="comment-header">
              <span className="comment-author">{comment.User.displayName}</span>
              <span className="comment-date">{formatDate(comment.createdAt)}</span>
            </div>
            <div className="comment-content">{comment.content}</div>
            <div className="comment-actions">
              <button
                onClick={() => handleDelete(comment.id)}
                className="delete-button"
              >
                Löschen
              </button>
            </div>
          </div>
        ))}
        
        {comments.length === 0 && (
          <div className="no-comments">
            Noch keine Kommentare vorhanden.
          </div>
        )}
      </div>
    </div>
  );
}

export default CommentTimeline; 