import React, { useState, useEffect } from 'react';
import api from '../api';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    email: '',
    displayName: '',
    role: 'user',
    isActive: true,
    notifyOnNewComments: false,
    notifyOnNewSubmissions: false
  });
  const [editingUser, setEditingUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const resetForm = () => {
    setFormData({
      email: '',
      displayName: '',
      role: 'user',
      isActive: true,
      notifyOnNewComments: false,
      notifyOnNewSubmissions: false
    });
    setEditingUser(null);
    setIsFormVisible(false);
    setIsEditModalVisible(false);
  };

  const toggleForm = () => {
    setIsFormVisible(!isFormVisible);
    if (isFormVisible) {
      resetForm();
    }
  };

  const openEditModal = (user) => {
    setFormData({
      email: user.email || '',
      displayName: user.displayName,
      role: user.role,
      isActive: user.isActive,
      notifyOnNewComments: Boolean(user.notifyOnNewComments),
      notifyOnNewSubmissions: Boolean(user.notifyOnNewSubmissions)
    });
    setEditingUser(user);
    setIsEditModalVisible(true);
  };

  const closeEditModal = () => {
    resetForm();
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get('/api/users');
      const formattedUsers = response.data.map(user => ({
        ...user,
        email: user.email || '',
        notifyOnNewComments: Boolean(user.notifyOnNewComments),
        notifyOnNewSubmissions: Boolean(user.notifyOnNewSubmissions)
      }));
      setUsers(formattedUsers);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Laden der Benutzer:', error);
      setError('Fehler beim Laden der Benutzer');
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    // Validierung der E-Mail-Adresse
    if (!formData.email || !formData.email.includes('@')) {
      setError('Bitte geben Sie eine gültige E-Mail-Adresse ein');
      return;
    }

    // Validierung des Anzeigenamens
    if (!formData.displayName.trim()) {
      setError('Bitte geben Sie einen Anzeigenamen ein');
      return;
    }

    const userData = {
      email: formData.email.trim(),
      displayName: formData.displayName.trim(),
      role: formData.role,
      isActive: true,
      notifyOnNewComments: formData.notifyOnNewComments,
      notifyOnNewSubmissions: formData.notifyOnNewSubmissions
    };

    try {
      if (editingUser) {
        await api.put(`/api/users/${editingUser.id}`, userData);
      } else {
        await api.post('/api/users', userData);
      }

      setSuccess(true);
      await fetchUsers();
      resetForm();
      setIsFormVisible(false);
      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      console.error('Fehler beim Speichern des Benutzers:', error);
      setError(
        error.response?.data?.message || 
        'Fehler beim Speichern des Benutzers. Bitte versuchen Sie es erneut.'
      );
    }
  };

  const handleUpdate = async (id, updates) => {
    try {
      await api.put(`/api/users/${id}`, updates);
      await fetchUsers();
      if (isEditModalVisible) {
        closeEditModal();
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Benutzers:', error);
      setError(error.response?.data?.message || 'Fehler beim Aktualisieren des Benutzers');
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const updates = { ...editingUser };
    
    // Behandle leere E-Mail-Adressen
    updates.email = updates.email.trim() || null;
    
    if (!updates.password) {
      delete updates.password; // Passwort nur senden, wenn es geändert wurde
    }
    await handleUpdate(editingUser.id, updates);
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Möchten Sie diesen Benutzer wirklich löschen?')) return;

    try {
      await api.delete(`/api/users/${id}`);
      fetchUsers();
    } catch (error) {
      console.error('Fehler beim Löschen des Benutzers:', error);
      setError(error.response?.data?.message || 'Fehler beim Löschen des Benutzers');
    }
  };

  if (loading) {
    return <div>Lade Benutzer...</div>;
  }

  return (
    <div className="user-management">
      <div className="user-management-header">
        <h2>Benutzerverwaltung</h2>
        <button 
          className="toggle-form-button"
          onClick={toggleForm}
          type="button"
        >
          {isFormVisible ? '- Formular ausblenden' : '+ Neuen Benutzer hinzufügen'}
        </button>
      </div>

      {error && (
        <div className="error-message">
          {error}
          <button onClick={() => setError(null)}>×</button>
        </div>
      )}

      {success && (
        <div className="success-message">
          Benutzer wurde erfolgreich gespeichert!
        </div>
      )}

      <div style={{ display: isFormVisible ? 'block' : 'none' }}>
        <form onSubmit={handleSubmit} className="user-form">
          <div className="form-group">
            <label>E-Mail:</label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({...formData, email: e.target.value})}
              required
              className="styled-input"
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <label>Anzeigename:</label>
            <input
              type="text"
              value={formData.displayName}
              onChange={(e) => setFormData({...formData, displayName: e.target.value})}
              required
              className="styled-input"
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <label>Rolle:</label>
            <select
              value={formData.role}
              onChange={(e) => setFormData({...formData, role: e.target.value})}
              className="styled-input"
            >
              <option value="user">Benutzer</option>
              <option value="admin">Administrator</option>
            </select>
          </div>

          <div className="form-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={formData.notifyOnNewComments}
                onChange={(e) => setFormData({...formData, notifyOnNewComments: e.target.checked})}
              />
              Bei neuen Kommentaren benachrichtigen
            </label>
          </div>

          <div className="form-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={formData.notifyOnNewSubmissions}
                onChange={(e) => setFormData({...formData, notifyOnNewSubmissions: e.target.checked})}
              />
              Bei neuen Einsendungen benachrichtigen
            </label>
          </div>

          <button type="submit" className="save-button">
            Benutzer erstellen
          </button>
        </form>
      </div>

      {isEditModalVisible && editingUser && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h3>Benutzer bearbeiten</h3>
              <button onClick={closeEditModal} className="close-button">×</button>
            </div>
            <form onSubmit={handleEditSubmit} className="user-form">
              <div className="form-group">
                <label>E-Mail:</label>
                <input
                  type="email"
                  value={editingUser.email || ''}
                  onChange={(e) => setEditingUser({...editingUser, email: e.target.value})}
                  className="styled-input"
                  autoComplete="off"
                />
              </div>

              <div className="form-group">
                <label>Anzeigename:</label>
                <input
                  type="text"
                  value={editingUser.displayName}
                  onChange={(e) => setEditingUser({...editingUser, displayName: e.target.value})}
                  required
                  className="styled-input"
                  autoComplete="off"
                />
              </div>

              <div className="form-group">
                <label>Rolle:</label>
                <select
                  value={editingUser.role}
                  onChange={(e) => setEditingUser({...editingUser, role: e.target.value})}
                  className="styled-input"
                >
                  <option value="user">Benutzer</option>
                  <option value="admin">Administrator</option>
                </select>
              </div>

              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={Boolean(editingUser.notifyOnNewComments)}
                    onChange={(e) => setEditingUser({...editingUser, notifyOnNewComments: e.target.checked})}
                  />
                  Bei neuen Kommentaren benachrichtigen
                </label>
              </div>

              <div className="form-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={Boolean(editingUser.notifyOnNewSubmissions)}
                    onChange={(e) => setEditingUser({...editingUser, notifyOnNewSubmissions: e.target.checked})}
                  />
                  Bei neuen Einsendungen benachrichtigen
                </label>
              </div>

              <div className="modal-footer">
                <button type="button" onClick={closeEditModal} className="cancel-button">
                  Abbrechen
                </button>
                <button type="submit" className="save-button">
                  Speichern
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="users-list">
        <div className="users-grid">
          {users.map(user => (
            <div key={user.id} className="user-card">
              <div className="user-info">
                <h4>{user.displayName}</h4>
                <p>Benutzername: {user.username}</p>
                <p>E-Mail: {user.email ? user.email : 'Keine'}</p>
                <p>Rolle: {user.role === 'admin' ? 'Administrator' : 'Benutzer'}</p>
                <p>Status: {user.isActive ? 'Aktiv' : 'Inaktiv'}</p>
                <p>Benachrichtigungen:</p>
                <ul>
                  <li>Neue Kommentare: {Boolean(user.notifyOnNewComments) ? 'Ja' : 'Nein'}</li>
                  <li>Neue Einsendungen: {Boolean(user.notifyOnNewSubmissions) ? 'Ja' : 'Nein'}</li>
                </ul>
              </div>
              <div className="user-actions">
                <button
                  onClick={() => openEditModal(user)}
                  className="edit-button"
                >
                  Bearbeiten
                </button>
                <button
                  onClick={() => handleUpdate(user.id, { isActive: !user.isActive })}
                  className={`status-button ${user.isActive ? 'active' : 'inactive'}`}
                >
                  {user.isActive ? 'Deaktivieren' : 'Aktivieren'}
                </button>
                <button
                  onClick={() => handleDelete(user.id)}
                  className="delete-button"
                >
                  Löschen
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserManagement; 