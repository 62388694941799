import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import logo from '../assets/logo.png';

function FeedbackForm() {
  const [feedback, setFeedback] = useState({
    type: 'suggestion',
    message: '',
    name: '',
    isAnonymous: false,
    image: null
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB Limit
        alert('Die Datei ist zu groß. Maximale Größe ist 5MB.');
        return;
      }
      setFeedback(prev => ({...prev, image: file}));
      alert('Bild erfolgreich ausgewählt: ' + file.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const typeMap = {
        'complaint': 'Beschwerde',
        'praise': 'Lob',
        'suggestion': 'Verbesserungsvorschlag'
      };
      
      const title = `${typeMap[feedback.type]}: ${feedback.message.substring(0, 50)}...`;
      
      const formData = new FormData();
      formData.append('type', feedback.type);
      formData.append('description', feedback.message);
      formData.append('author', feedback.isAnonymous ? 'Anonym' : feedback.name);
      formData.append('title', title);
      
      if (feedback.image) {
        formData.append('image', feedback.image);
      }

      const response = await api({
        method: 'post',
        url: '/projects',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      if (response.status === 201) {
        alert('Vielen Dank für Ihr Feedback!');
        setFeedback({
          type: 'suggestion',
          message: '',
          name: '',
          isAnonymous: false,
          image: null
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else {
        throw new Error('Unerwartete Antwort vom Server');
      }
    } catch (error) {
      console.error('Fehler beim Senden des Feedbacks:', error);
      const errorMessage = error.response?.data?.error || error.message || 'Unbekannter Fehler';
      alert(`Fehler beim Senden des Feedbacks: ${errorMessage}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="feedback-page">
      <header className="feedback-header">
        <img src={logo} alt="Logo" className="logo" />
        <span className="header-text">Feedback</span>
      </header>

      <div className="feedback-container">
        <h1>Deine Meinung zählt – Gestalte mit uns eine bessere Zukunft! 🌟</h1>
        
        <p className="intro-text">
          Wir möchten uns stetig verbessern – für dich und dein Arbeitsumfeld! 💫 Ob es um langatmige Prozesse, unklare Abläufe, fehlende Tools oder andere Herausforderungen in deinem Arbeitsalltag geht: Dein Feedback hilft uns, diese Hürden zu erkennen und gemeinsam Lösungen zu finden. 💪
        </p>

        <p className="intro-text">
          Vielleicht stört dich ein unnötig komplizierter Ablauf? 🤔 Oder du hast eine Idee, wie wir unsere Kommunikation oder Zusammenarbeit noch effizienter gestalten können? ✨ Egal ob kleine Unannehmlichkeiten oder große Verbesserungsvorschläge – wir möchten deine Gedanken dazu hören! 📝
        </p>

        <p className="intro-text">
          Deine Meinung ist der Schlüssel zu besseren Prozessen und einem Arbeitsumfeld, in dem du dich wohlfühlst! 🔑 Teil dein Feedback, anonym oder mit deinem Namen, und gestalte aktiv unsere Zukunft mit! 🌟✨
        </p>

        <form onSubmit={handleSubmit} className="feedback-form">
          <div className="feedback-type">
            <h3>Art des Feedbacks</h3>
            <div className="type-buttons">
              <button
                type="button"
                className={feedback.type === 'complaint' ? 'active' : ''}
                onClick={() => setFeedback({...feedback, type: 'complaint'})}
                style={{
                  backgroundColor: feedback.type === 'complaint' ? '#E8fc6c' : 'transparent',
                  color: feedback.type === 'complaint' ? '#000' : '#E8fc6c'
                }}
              >
                Beschwerde
              </button>
              <button
                type="button"
                className={feedback.type === 'praise' ? 'active' : ''}
                onClick={() => setFeedback({...feedback, type: 'praise'})}
                style={{
                  backgroundColor: feedback.type === 'praise' ? '#E8fc6c' : 'transparent',
                  color: feedback.type === 'praise' ? '#000' : '#E8fc6c'
                }}
              >
                Lob
              </button>
              <button
                type="button"
                className={feedback.type === 'suggestion' ? 'active' : ''}
                onClick={() => setFeedback({...feedback, type: 'suggestion'})}
                style={{
                  backgroundColor: feedback.type === 'suggestion' ? '#E8fc6c' : 'transparent',
                  color: feedback.type === 'suggestion' ? '#000' : '#E8fc6c'
                }}
              >
                Verbesserungsvorschlag
              </button>
            </div>
          </div>

          <div className="form-group">
            <label>Ihre Nachricht:</label>
            <textarea
              value={feedback.message}
              onChange={(e) => setFeedback({...feedback, message: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <div className="custom-checkbox">
              <input
                type="checkbox"
                id="anonymousCheck"
                checked={feedback.isAnonymous}
                onChange={(e) => setFeedback({...feedback, isAnonymous: e.target.checked})}
              />
              <label htmlFor="anonymousCheck">Anonym einreichen</label>
            </div>
          </div>

          {!feedback.isAnonymous && (
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                value={feedback.name}
                onChange={(e) => setFeedback({...feedback, name: e.target.value})}
              />
            </div>
          )}

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            accept="image/*"
          />

          <div className="type-buttons">
            <button 
              type="button" 
              onClick={() => fileInputRef.current?.click()}
              disabled={isSubmitting}
            >
              <span className="upload-icon">📎</span> Bild anhängen
            </button>
          </div>

          {feedback.image && (
            <div className="image-preview">
              <p>Ausgewähltes Bild: {feedback.image.name}</p>
              <button
                type="button"
                onClick={() => {
                  setFeedback(prev => ({...prev, image: null}));
                  if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                  }
                }}
                style={{
                  backgroundColor: 'transparent',
                  color: '#E8fc6c',
                  border: '1px solid #E8fc6c',
                  padding: '0.5rem',
                  marginTop: '0.5rem',
                  cursor: 'pointer'
                }}
                disabled={isSubmitting}
              >
                Bild entfernen
              </button>
            </div>
          )}

          <div className="type-buttons">
            <button 
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Wird gesendet...' : 'Feedback senden'}
            </button>
          </div>
        </form>

        <div className="admin-link">
          <Link to="/admin">Zum Admin-Bereich</Link>
        </div>
      </div>
    </div>
  );
}

export default FeedbackForm; 