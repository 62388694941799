import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PresentationContext, AuthContext } from '../App';

function AdminLayout({ children }) {
  const location = useLocation();
  const { presentationMode, togglePresentationMode } = useContext(PresentationContext);
  const { handleLogout, user } = useContext(AuthContext);
  const isAdmin = user?.role === 'admin';

  return (
    <div className="admin-container">
      <nav className="admin-nav">
        <img src="/assets/qp-logo.png" alt="Quadrat P Logo" className="logo" />
        
        <Link 
          to="/admin/dashboard"
          className={location.pathname === '/admin/dashboard' ? 'active' : ''}
        >
          Dashboard
        </Link>
        
        {isAdmin && (
          <>
            <Link 
              to="/admin/users"
              className={location.pathname === '/admin/users' ? 'active' : ''}
            >
              Benutzer
            </Link>
            
            <Link 
              to="/admin/departments"
              className={location.pathname === '/admin/departments' ? 'active' : ''}
            >
              Abteilungen
            </Link>
          </>
        )}
        
        <Link 
          to="/admin/analytics"
          className={location.pathname === '/admin/analytics' ? 'active' : ''}
        >
          Auswertung
        </Link>

        {isAdmin && (
          <Link 
            to="/admin/settings"
            className={location.pathname === '/admin/settings' ? 'active' : ''}
          >
            Einstellungen
          </Link>
        )}

        <div className="nav-actions">
          <button
            className={`presentation-mode-button ${presentationMode ? 'active' : ''}`}
            onClick={togglePresentationMode}
          >
            {presentationMode ? 'Präsentationsmodus aktiv' : 'Präsentationsmodus'}
          </button>
          
          <button 
            className="logout-button"
            onClick={handleLogout}
          >
            Abmelden
          </button>
        </div>
      </nav>

      <main className="admin-content">
        {children}
      </main>
    </div>
  );
}

export default AdminLayout; 