import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../App';

function AdminRoute({ children }) {
  const { user } = useContext(AuthContext);
  const isAdmin = user?.role === 'admin';

  if (!isAdmin) {
    return <Navigate to="/admin/dashboard" replace />;
  }

  return children;
}

export default AdminRoute; 