import React, { useState, useEffect } from 'react';
import SmtpConfig from './SmtpConfig';
import api from '../api';

function Settings() {
  const [activeTab, setActiveTab] = useState('smtp');
  const [activeTemplate, setActiveTemplate] = useState('feedback');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [templates, setTemplates] = useState({
    feedback: {
      type: 'feedback',
      name: 'Neue Einsendung',
      // eslint-disable-next-line no-template-curly-in-string
      subject: 'Neues Feedback: ${projectTitle}',
      content: `<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Neues Feedback</title>
</head>
<body style="margin: 0; padding: 20px; background-color: #1a1a1a; font-family: Arial, sans-serif;">
  <div style="max-width: 600px; margin: 0 auto; background-color: #2a2a2a; border-radius: 8px; overflow: hidden; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
    <!-- Header -->
    <div style="background-color: #333; padding: 20px; text-align: center;">
      <h1 style="color: #E8fc6c; font-size: 24px; margin: 0;">Neues Feedback</h1>
    </div>

    <!-- Content -->
    <div style="padding: 30px;">
      <p style="color: #ffffff; font-size: 16px; line-height: 1.5; margin-bottom: 25px;">
        Es wurde ein neues Feedback in unserem System eingereicht.
      </p>

      <!-- Feedback Details -->
      <div style="background-color: #333; padding: 20px; border-radius: 6px; margin-bottom: 25px;">
        <div style="margin-bottom: 15px;">
          <div style="color: #E8fc6c; font-size: 14px; font-weight: bold; margin-bottom: 5px;">Titel</div>
          <div style="color: #ffffff; font-size: 16px;">\${projectTitle}</div>
        </div>

        <div style="margin-bottom: 15px;">
          <div style="color: #E8fc6c; font-size: 14px; font-weight: bold; margin-bottom: 5px;">Typ</div>
          <div style="color: #ffffff; font-size: 16px;">\${projectType}</div>
        </div>

        <div style="margin-bottom: 15px;">
          <div style="color: #E8fc6c; font-size: 14px; font-weight: bold; margin-bottom: 5px;">Autor</div>
          <div style="color: #ffffff; font-size: 16px;">\${projectAuthor}</div>
        </div>

        <div>
          <div style="color: #E8fc6c; font-size: 14px; font-weight: bold; margin-bottom: 5px;">Beschreibung</div>
          <div style="color: #ffffff; font-size: 16px; white-space: pre-wrap;">\${projectDescription}</div>
        </div>
      </div>

      <!-- Action Button -->
      <div style="text-align: center;">
        <a href="\${adminUrl}" 
           style="display: inline-block; background-color: #E8fc6c; color: #000000; padding: 12px 30px; text-decoration: none; border-radius: 4px; font-weight: bold; font-size: 16px;">
          Im Admin-Bereich anzeigen
        </a>
      </div>
    </div>

    <!-- Footer -->
    <div style="background-color: #333; padding: 20px; text-align: center;">
      <p style="color: #888888; font-size: 14px; margin: 0;">
        © \${new Date().getFullYear()} Feedback System
      </p>
    </div>
  </div>
</body>
</html>`,
      plainText: `Neues Feedback eingereicht

Titel: \${projectTitle}
Typ: \${projectType}
Autor: \${projectAuthor}

Beschreibung:
\${projectDescription}

Zum Anzeigen im Admin-Bereich: \${adminUrl}`
    },
    comment: {
      type: 'comment',
      name: 'Neuer Kommentar',
      // eslint-disable-next-line no-template-curly-in-string
      subject: 'Neuer Kommentar zu: ${projectTitle}',
      content: `<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Neuer Kommentar</title>
</head>
<body style="margin: 0; padding: 20px; background-color: #1a1a1a; font-family: Arial, sans-serif;">
  <div style="max-width: 600px; margin: 0 auto; background-color: #2a2a2a; border-radius: 8px; overflow: hidden; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
    <!-- Header -->
    <div style="background-color: #333; padding: 20px; text-align: center;">
      <h1 style="color: #E8fc6c; font-size: 24px; margin: 0;">Neuer Kommentar</h1>
    </div>

    <!-- Content -->
    <div style="padding: 30px;">
      <p style="color: #ffffff; font-size: 16px; line-height: 1.5; margin-bottom: 25px;">
        Es wurde ein neuer Kommentar zu einem Feedback hinzugefügt.
      </p>

      <!-- Comment Details -->
      <div style="background-color: #333; padding: 20px; border-radius: 6px; margin-bottom: 25px;">
        <div style="margin-bottom: 15px;">
          <div style="color: #E8fc6c; font-size: 14px; font-weight: bold; margin-bottom: 5px;">Feedback</div>
          <div style="color: #ffffff; font-size: 16px;">\${projectTitle}</div>
        </div>

        <div>
          <div style="color: #E8fc6c; font-size: 14px; font-weight: bold; margin-bottom: 5px;">Kommentar</div>
          <div style="color: #ffffff; font-size: 16px; white-space: pre-wrap;">\${commentContent}</div>
        </div>
      </div>

      <!-- Action Button -->
      <div style="text-align: center;">
        <a href="\${adminUrl}" 
           style="display: inline-block; background-color: #E8fc6c; color: #000000; padding: 12px 30px; text-decoration: none; border-radius: 4px; font-weight: bold; font-size: 16px;">
          Im Admin-Bereich anzeigen
        </a>
      </div>
    </div>

    <!-- Footer -->
    <div style="background-color: #333; padding: 20px; text-align: center;">
      <p style="color: #888888; font-size: 14px; margin: 0;">
        © \${new Date().getFullYear()} Feedback System
      </p>
    </div>
  </div>
</body>
</html>`,
      plainText: `Neuer Kommentar zu Feedback

Feedback: \${projectTitle}

Kommentar:
\${commentContent}

Zum Anzeigen im Admin-Bereich: \${adminUrl}`
    },
    deadline: {
      type: 'deadline',
      name: 'Frist-Erinnerung',
      // eslint-disable-next-line no-template-curly-in-string
      subject: 'Erinnerung: Frist für Feedback ${projectTitle}',
      content: `<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Frist-Erinnerung</title>
</head>
<body style="margin: 0; padding: 20px; background-color: #1a1a1a; font-family: Arial, sans-serif;">
  <div style="max-width: 600px; margin: 0 auto; background-color: #2a2a2a; border-radius: 8px; overflow: hidden; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
    <!-- Header -->
    <div style="background-color: #333; padding: 20px; text-align: center;">
      <h1 style="color: #E8fc6c; font-size: 24px; margin: 0;">Frist-Erinnerung</h1>
    </div>

    <!-- Content -->
    <div style="padding: 30px;">
      <p style="color: #ffffff; font-size: 16px; line-height: 1.5; margin-bottom: 25px;">
        Die Bearbeitungsfrist für ein Feedback nähert sich dem Ende.
      </p>

      <!-- Deadline Details -->
      <div style="background-color: #333; padding: 20px; border-radius: 6px; margin-bottom: 25px;">
        <div style="margin-bottom: 15px;">
          <div style="color: #E8fc6c; font-size: 14px; font-weight: bold; margin-bottom: 5px;">Feedback</div>
          <div style="color: #ffffff; font-size: 16px;">\${projectTitle}</div>
        </div>

        <div style="margin-bottom: 15px;">
          <div style="color: #E8fc6c; font-size: 14px; font-weight: bold; margin-bottom: 5px;">Frist</div>
          <div style="color: #ffffff; font-size: 16px;">\${deadline}</div>
        </div>

        <div>
          <div style="color: #E8fc6c; font-size: 14px; font-weight: bold; margin-bottom: 5px;">Status</div>
          <div style="color: #ffffff; font-size: 16px;">\${projectStatus}</div>
        </div>
      </div>

      <!-- Action Button -->
      <div style="text-align: center;">
        <a href="\${adminUrl}" 
           style="display: inline-block; background-color: #E8fc6c; color: #000000; padding: 12px 30px; text-decoration: none; border-radius: 4px; font-weight: bold; font-size: 16px;">
          Im Admin-Bereich anzeigen
        </a>
      </div>
    </div>

    <!-- Footer -->
    <div style="background-color: #333; padding: 20px; text-align: center;">
      <p style="color: #888888; font-size: 14px; margin: 0;">
        © \${new Date().getFullYear()} Feedback System
      </p>
    </div>
  </div>
</body>
</html>`,
      plainText: `Frist-Erinnerung für Feedback

Feedback: \${projectTitle}
Frist: \${deadline}
Status: \${projectStatus}

Zum Anzeigen im Admin-Bereich: \${adminUrl}`
    }
  });

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/email-templates');
      const fetchedTemplates = {};
      
      response.data.forEach(template => {
        fetchedTemplates[template.type] = template;
      });
      
      setTemplates(prev => ({
        ...prev,
        ...fetchedTemplates
      }));
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Laden der E-Mail-Templates:', error);
      setError('Fehler beim Laden der E-Mail-Templates');
      setLoading(false);
    }
  };

  const handleTemplateChange = (field, value) => {
    setTemplates(prev => ({
      ...prev,
      [activeTemplate]: {
        ...prev[activeTemplate],
        [field]: value
      }
    }));
  };

  const handleSaveTemplate = async () => {
    try {
      setError(null);
      const template = templates[activeTemplate];
      
      await api.put(`/api/email-templates/${template.type}`, template);
      
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      console.error('Fehler beim Speichern des Templates:', error);
      setError('Fehler beim Speichern des Templates');
    }
  };

  const renderTemplateNav = () => (
    <div className="template-nav">
      <button 
        className={`template-nav-button ${activeTemplate === 'feedback' ? 'active' : ''}`}
        onClick={() => setActiveTemplate('feedback')}
      >
        Neue Einsendung
      </button>
      <button 
        className={`template-nav-button ${activeTemplate === 'comment' ? 'active' : ''}`}
        onClick={() => setActiveTemplate('comment')}
      >
        Neue Kommentare
      </button>
      <button 
        className={`template-nav-button ${activeTemplate === 'deadline' ? 'active' : ''}`}
        onClick={() => setActiveTemplate('deadline')}
      >
        Deadline Erinnerungen
      </button>
    </div>
  );

  const renderTemplateEditor = () => {
    const template = templates[activeTemplate];
    if (!template) return null;

    return (
      <>
        <div className="form-group">
          <label>Betreff:</label>
          <input
            type="text"
            className="styled-input"
            value={template.subject}
            onChange={(e) => handleTemplateChange('subject', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>HTML Inhalt:</label>
          <textarea
            className="styled-textarea code-editor"
            rows="15"
            value={template.content}
            onChange={(e) => handleTemplateChange('content', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Text Version:</label>
          <textarea
            className="styled-textarea"
            rows="10"
            value={template.plainText}
            onChange={(e) => handleTemplateChange('plainText', e.target.value)}
          />
        </div>

        <div className="template-variables">
          <h4>Verfügbare Variablen:</h4>
          {activeTemplate === 'feedback' && (
            <ul>
              {/* eslint-disable no-template-curly-in-string */}
              <li><code>${'${projectTitle}'}</code> - Titel des Feedbacks</li>
              <li><code>${'${projectType}'}</code> - Typ des Feedbacks</li>
              <li><code>${'${projectAuthor}'}</code> - Name des Autors</li>
              <li><code>${'${projectDescription}'}</code> - Beschreibung des Feedbacks</li>
              <li><code>${'${adminUrl}'}</code> - Link zum Admin-Bereich</li>
              {/* eslint-enable no-template-curly-in-string */}
            </ul>
          )}
          {activeTemplate === 'comment' && (
            <ul>
              {/* eslint-disable no-template-curly-in-string */}
              <li><code>${'${projectTitle}'}</code> - Titel des Feedbacks</li>
              <li><code>${'${commentContent}'}</code> - Inhalt des Kommentars</li>
              <li><code>${'${adminUrl}'}</code> - Link zum Admin-Bereich</li>
              {/* eslint-enable no-template-curly-in-string */}
            </ul>
          )}
          {activeTemplate === 'deadline' && (
            <ul>
              {/* eslint-disable no-template-curly-in-string */}
              <li><code>${'${projectTitle}'}</code> - Titel des Feedbacks</li>
              <li><code>${'${deadline}'}</code> - Deadline-Datum</li>
              <li><code>${'${projectStatus}'}</code> - Aktueller Status</li>
              <li><code>${'${adminUrl}'}</code> - Link zum Admin-Bereich</li>
              {/* eslint-enable no-template-curly-in-string */}
            </ul>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="settings-page">
      <div className="settings-container">
        <h2>Einstellungen</h2>
        
        <div className="settings-tabs">
          <button 
            className={`tab-button ${activeTab === 'smtp' ? 'active' : ''}`}
            onClick={() => setActiveTab('smtp')}
          >
            SMTP-Konfiguration
          </button>
          <button 
            className={`tab-button ${activeTab === 'template' ? 'active' : ''}`}
            onClick={() => setActiveTab('template')}
          >
            E-Mail-Templates
          </button>
        </div>

        {activeTab === 'smtp' && (
          <div className="settings-section">
            <h3>E-Mail-Benachrichtigungen</h3>
            <SmtpConfig />
          </div>
        )}

        {activeTab === 'template' && (
          <div className="settings-section">
            <h3>E-Mail-Templates</h3>
            {error && (
              <div className="error-message">
                <p>{error}</p>
                <button onClick={() => setError(null)}>×</button>
              </div>
            )}
            {success && (
              <div className="success-message">
                Template wurde erfolgreich gespeichert!
              </div>
            )}
            {loading ? (
              <div className="loading">Lade Templates...</div>
            ) : (
              <>
                {renderTemplateNav()}
                <div className="template-editor">
                  {renderTemplateEditor()}
                  <button 
                    type="button" 
                    className="save-button"
                    onClick={handleSaveTemplate}
                  >
                    Template speichern
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Settings; 