import React, { useState, useEffect } from 'react';
import api from '../api';

function DepartmentManager() {
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState('');
  const [editingDepartment, setEditingDepartment] = useState(null);
  const [editName, setEditName] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await api.get('/api/departments');
      setDepartments(response.data);
    } catch (error) {
      console.error('Fehler beim Laden der Abteilungen:', error);
      setError('Fehler beim Laden der Abteilungen');
    }
  };

  const handleAddDepartment = async () => {
    if (!newDepartment.trim()) return;

    try {
      await api.post('/api/departments', { name: newDepartment.trim() });
      setNewDepartment('');
      fetchDepartments();
    } catch (error) {
      console.error('Fehler beim Hinzufügen der Abteilung:', error);
      setError('Fehler beim Hinzufügen der Abteilung');
    }
  };

  const handleEditDepartment = async (id) => {
    if (!editName.trim()) return;

    try {
      await api.put(`/api/departments/${id}`, { name: editName.trim() });
      setEditingDepartment(null);
      setEditName('');
      fetchDepartments();
    } catch (error) {
      console.error('Fehler beim Bearbeiten der Abteilung:', error);
      setError('Fehler beim Bearbeiten der Abteilung');
    }
  };

  const handleDeleteDepartment = async (id) => {
    if (!window.confirm('Möchten Sie diese Abteilung wirklich löschen?')) return;

    try {
      await api.delete(`/api/departments/${id}`);
      fetchDepartments();
    } catch (error) {
      console.error('Fehler beim Löschen der Abteilung:', error);
      setError('Fehler beim Löschen der Abteilung');
    }
  };

  const startEditing = (department) => {
    setEditingDepartment(department.id);
    setEditName(department.name);
  };

  return (
    <div className="department-manager">
      <h3>Abteilungen verwalten</h3>
      
      {error && (
        <div className="error-message">
          {error}
          <button onClick={() => setError(null)}>×</button>
        </div>
      )}

      <div className="department-form">
        <input
          type="text"
          value={newDepartment}
          onChange={(e) => setNewDepartment(e.target.value)}
          placeholder="Neue Abteilung"
          className="department-input"
        />
        <button onClick={handleAddDepartment} className="add-department-button">
          Hinzufügen
        </button>
      </div>

      <div className="departments-list">
        {departments.map(department => (
          <div key={department.id} className="department-item">
            {editingDepartment === department.id ? (
              <div className="department-edit">
                <input
                  type="text"
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                  className="department-input"
                />
                <button 
                  onClick={() => handleEditDepartment(department.id)}
                  className="save-button"
                >
                  Speichern
                </button>
                <button 
                  onClick={() => setEditingDepartment(null)}
                  className="cancel-button"
                >
                  Abbrechen
                </button>
              </div>
            ) : (
              <div className="department-display">
                <span>{department.name}</span>
                <div className="department-actions">
                  <button 
                    onClick={() => startEditing(department)}
                    className="edit-button"
                  >
                    Bearbeiten
                  </button>
                  <button 
                    onClick={() => handleDeleteDepartment(department.id)}
                    className="delete-button"
                  >
                    Löschen
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DepartmentManager; 