import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import '../styles/Login.css';
import logo from '../assets/logo.png';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;
  console.log('API URL:', apiUrl);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await api.post('/api/auth/login', { email, password });
      const { token, user } = response.data;
      
      if (!token || !user || typeof user !== 'object') {
        throw new Error('Ungültige Server-Antwort');
      }

      try {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        onLogin(token, user);
        navigate('/admin/dashboard');
      } catch (storageError) {
        console.error('Fehler beim Speichern der Login-Daten:', storageError);
        throw new Error('Fehler beim Speichern der Login-Daten');
      }

    } catch (error) {
      console.error('Login error:', error);
      setError(error.message === 'Ungültige Server-Antwort' || error.message === 'Fehler beim Speichern der Login-Daten'
        ? error.message 
        : 'Ungültige Anmeldedaten. Bitte versuchen Sie es erneut.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="admin-container">
      <div className="admin-nav">
        <img src={logo} alt="QP Logo" className="logo" />
        <span className="header-text">QP-Feedback Admin</span>
      </div>
      
      <div className="admin-content">
        <div className="settings-container">
          <h2>Admin Login</h2>
          
          {error && (
            <div className="error-message">
              {error}
            </div>
          )}
          
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <label htmlFor="email">E-Mail</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="styled-input"
                autoComplete="email"
                placeholder="E-Mail-Adresse eingeben"
                disabled={isLoading}
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="password">Passwort</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="styled-input"
                autoComplete="current-password"
                placeholder="Passwort eingeben"
                disabled={isLoading}
              />
            </div>
            
            <button 
              type="submit" 
              className="save-button"
              disabled={isLoading}
            >
              {isLoading ? 'Anmeldung...' : 'Anmelden'}
            </button>
          </form>
          
          {process.env.NODE_ENV === 'development' && (
            <div style={{ marginTop: '20px', color: '#666', fontSize: '12px' }}>
              API URL: {apiUrl}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login; 