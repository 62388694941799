import React, { useState, useEffect } from 'react';
import api from '../api';

function SmtpConfig() {
  const [config, setConfig] = useState({
    host: '',
    port: '',
    secure: true,
    auth: {
      user: '',
      pass: ''
    },
    from: ''
  });
  const [testEmail, setTestEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [testStatus, setTestStatus] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Auth Token:', token);
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      console.log('Fetching SMTP config...');
      const response = await api.get('/api/smtp/config');
      console.log('SMTP config response:', response.data);
      setConfig({
        host: response.data.host || '',
        port: response.data.port || '',
        secure: response.data.secure ?? true,
        auth: {
          user: response.data.auth_user || '',
          pass: ''
        },
        from: response.data.from_email || ''
      });
      setLoading(false);
    } catch (error) {
      console.error('SMTP config error:', error.response || error);
      if (error.response?.status === 404) {
        setLoading(false);
      } else {
        console.error('Fehler beim Laden der SMTP-Konfiguration:', error);
        setError('Fehler beim Laden der SMTP-Konfiguration');
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    try {
      const response = await api.post('/api/smtp/config', {
        host: config.host,
        port: config.port,
        secure: config.secure,
        auth_user: config.auth.user,
        auth_pass: config.auth.pass || undefined,
        from_email: config.from
      });

      setSuccess(true);
      if (response.data.config) {
        setConfig({
          host: response.data.config.host,
          port: response.data.config.port,
          secure: response.data.config.secure,
          auth: {
            user: response.data.config.auth_user,
            pass: ''
          },
          from: response.data.config.from_email
        });
      }
      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      console.error('Fehler beim Speichern der SMTP-Konfiguration:', error);
      let errorMessage = 'Fehler beim Speichern der SMTP-Konfiguration';
      
      if (error.response?.data) {
        errorMessage = error.response.data.message;
        if (error.response.data.details) {
          if (Array.isArray(error.response.data.details)) {
            errorMessage += '\n' + error.response.data.details.join('\n');
          } else {
            errorMessage += '\n' + error.response.data.details;
          }
        }
        if (error.response.data.error) {
          errorMessage += '\n\nDetails: ' + error.response.data.error;
        }
      }
      
      setError(errorMessage);
    }
  };

  const handleTestEmail = async () => {
    setTestStatus(null);
    setError(null);

    if (!testEmail) {
      setError('Bitte geben Sie eine Test-E-Mail-Adresse ein');
      return;
    }

    try {
      await api.post('/api/smtp/test', { testEmail });
      setTestStatus('success');
      setTimeout(() => setTestStatus(null), 3000);
    } catch (error) {
      console.error('Fehler beim Senden der Test-E-Mail:', error);
      setTestStatus('error');
      const errorDetails = error.response?.data;
      let errorMessage = 'Fehler beim Senden der Test-E-Mail';
      
      if (errorDetails) {
        errorMessage = `${errorDetails.message}\n`;
        if (errorDetails.error) {
          errorMessage += `\nDetails: ${errorDetails.error}`;
        }
        if (errorDetails.config) {
          errorMessage += '\n\nAktuelle Konfiguration:';
          errorMessage += `\nHost: ${errorDetails.config.host}`;
          errorMessage += `\nPort: ${errorDetails.config.port}`;
          errorMessage += `\nBenutzer: ${errorDetails.config.auth_user}`;
          errorMessage += `\nAbsender: ${errorDetails.config.from_email}`;
        }
      }
      
      setError(errorMessage);
    }
  };

  if (loading) {
    return <div className="smtp-config">Lade SMTP-Konfiguration...</div>;
  }

  return (
    <div className="smtp-config">
      <h2>SMTP-Konfiguration</h2>

      {error && (
        <div className="error-message">
          <pre>{error}</pre>
          <button onClick={() => setError(null)}>×</button>
        </div>
      )}

      {success && (
        <div className="success-message">
          Konfiguration wurde erfolgreich gespeichert!
        </div>
      )}

      {testStatus === 'success' && (
        <div className="success-message">
          Test-E-Mail wurde erfolgreich versendet!
        </div>
      )}

      <form 
        onSubmit={handleSubmit} 
        className="config-form" 
        autoComplete="off" 
        autoCorrect="off" 
        autoCapitalize="off" 
        spellCheck="false"
      >
        <div className="form-group">
          <label>SMTP Server:</label>
          <input
            type="text"
            value={config.host}
            onChange={(e) => setConfig({...config, host: e.target.value})}
            required
            className="styled-input"
            placeholder="z.B. smtp.gmail.com"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </div>

        <div className="form-group">
          <label>Port:</label>
          <input
            type="number"
            value={config.port}
            onChange={(e) => setConfig({...config, port: e.target.value})}
            required
            className="styled-input"
            placeholder="z.B. 587"
            autoComplete="off"
          />
        </div>

        <div className="form-group">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={config.secure}
              onChange={(e) => setConfig({...config, secure: e.target.checked})}
            />
            SSL/TLS verwenden
          </label>
        </div>

        <div className="form-group">
          <label>Benutzername:</label>
          <input
            type="text"
            name="smtp_username"
            value={config.auth.user}
            onChange={(e) => setConfig({
              ...config,
              auth: { ...config.auth, user: e.target.value }
            })}
            required
            className="styled-input"
            placeholder="E-Mail-Adresse oder Benutzername"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            data-lpignore="true"
            data-form-type="other"
          />
        </div>

        <div className="form-group">
          <label>Passwort:</label>
          <input
            type="password"
            name="smtp_password"
            value={config.auth.pass}
            onChange={(e) => setConfig({
              ...config,
              auth: { ...config.auth, pass: e.target.value }
            })}
            required={!config.auth.pass}
            className="styled-input"
            placeholder="SMTP Passwort oder App-Passwort"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            data-lpignore="true"
            data-form-type="other"
          />
          <small className="help-text">
            Leer lassen, um das bestehende Passwort beizubehalten
          </small>
        </div>

        <div className="form-group">
          <label>Absender-E-Mail:</label>
          <input
            type="email"
            value={config.from}
            onChange={(e) => setConfig({...config, from: e.target.value})}
            required
            className="styled-input"
            placeholder="noreply@example.com"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </div>

        <div className="button-group">
          <button type="submit" className="save-button">
            Konfiguration speichern
          </button>
          <div className="test-email-group">
            <input
              type="email"
              value={testEmail}
              onChange={(e) => setTestEmail(e.target.value)}
              placeholder="Test-E-Mail-Empfänger"
              className="styled-input"
              style={{ marginRight: '1rem', width: 'auto' }}
            />
            <button 
              type="button" 
              onClick={handleTestEmail} 
              className="test-email-button"
              disabled={loading || !testEmail}
            >
              Test-E-Mail senden
            </button>
          </div>
        </div>
      </form>

      <div className="smtp-info">
        <h3>Hilfe zur SMTP-Konfiguration</h3>
        <p>
          Für die E-Mail-Benachrichtigungen wird ein SMTP-Server benötigt. 
          Hier sind einige gängige SMTP-Einstellungen:
        </p>
        <ul>
          <li>
            <strong>Gmail:</strong>
            <ul>
              <li>SMTP Server: smtp.gmail.com</li>
              <li>Port: 587</li>
              <li>SSL/TLS: Ja</li>
              <li>Hinweis: App-Passwort erforderlich bei 2FA</li>
            </ul>
          </li>
          <li>
            <strong>Outlook/Office 365:</strong>
            <ul>
              <li>SMTP Server: smtp.office365.com</li>
              <li>Port: 587</li>
              <li>SSL/TLS: Ja</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SmtpConfig; 